<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.dayEnd"
              maxLength="7"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayStartChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy.MM"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="7"
              :min="searchType.customTypes.dayStart"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onDayEndChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" ref="personalEvaluation" />

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDataGrid, DxSelection, DxColumn } from 'devextreme-vue/data-grid';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';

  import ModalRecordList from '@/pages/ewm/personal/evaluation/modal-record-list.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,
      DxDataGridx: DxDataGrid,
      DxSelection,
      DxColumn,

      DxPopup,
      DxToolbarItem,
      ModalRecordList,

      DxRequiredRule,
      DxValidator,
    },
    props: {},
    watch: {},
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        codes: {
          evalDivisionCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          evalProcessCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        dataGrid: {
          keyExpr: 'id',
          refName: 'personalEvaluation',
          showMenuColumns: true, //컬럼 표시
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          hoverStateEnabled: true, //마우스커서 행 강조
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 370px)', // 주석처리시 100%
          showActionButtons: {},
          customEvent: {
            cellHoverChanged: true,
            cellPrepared: false,
          },
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              dataField: 'evalOrd',
              alignment: 'center',
              width: 40,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              caption: '평가구분',
              dataField: 'evalDivisionCd',
              alignment: 'center',
              visible: true,
              width: 140,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowSorting: true,
              lookup: {},
            },
            {
              caption: '평가계획명',
              dataField: 'scheNm',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '회차',
              dataField: 'evalRound',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 80,
            },
            {
              caption: '기준년월',
              dataField: 'scheYmd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 100,
              calculateDisplayCellValue: rowData => {
                return `${this.$_commonlib.formatDate(rowData.scheYmd, 'YYYY-MM-DD', 'YYYY.MM')}`;
              },
            },
            {
              caption: '평가기간',
              dataField: 'evalDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 170,
              calculateDisplayCellValue: rowData => {
                return `${rowData.evalStartDt} ~ ${rowData.evalEndDt}`;
              },
            },

            {
              caption: '이의제기기간',
              dataField: 'evalFeedbackDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 170,
              calculateDisplayCellValue: rowData => {
                return `${rowData.evalFeedbackStartDt} ~ ${rowData.evalFeedbackEndDt}`;
              },
            },
            {
              caption: '진행상태',
              dataField: 'evalProcessCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowSorting: true,
              width: 130,
              lookup: {
                dataSource: this.$_enums.ewm.evalProcess.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '본인점수',
              dataField: 'evalScore',
              dataType: 'number',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowSorting: true,
              width: 100,
            },
            {
              caption: '평가',
              dataField: 'personalEval',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowSorting: false,
              width: 70,
              cellTemplate: (container, options) => {
                let Button = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'search',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.data,
                    onClick: () => {
                      vm.onPersonalEval(options.data);
                    },
                  },
                });
                Button.$mount();

                if (
                  options.data.evalProcessCd === this.$_enums.ewm.evalProcess.FEEDBACK.value ||
                  options.data.evalProcessCd === this.$_enums.ewm.evalProcess.FEEDBACK_COMPLETE.value ||
                  options.data.evalProcessCd === this.$_enums.ewm.evalProcess.FINISH.value
                ) {
                  container.append(Button.$el);
                } else {
                  container.innerHTML = '-';
                }
              },
            },
            {
              caption: '녹취',
              dataField: 'record',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowSorting: false,
              width: 80,
              cellTemplate: (container, options) => {
                if (options.data.evalDivisionCd == this.codes.evalDivisionCd.dataSource.find(e => e.codeNm == '일반평가').codeId) {
                  container.innerHTML = '-';
                } else {
                  const button = new DxButton({
                    propsData: {
                      template: '<span class="mdi mdi-volume-high"></span>',
                      elementAttr: { class: 'white light_filled txt_S' },
                      value: options.data,
                      onClick: () => {
                        vm.onRecordList(options.data);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 평가 */
      async onPersonalEval(data) {
        let params = { data: data };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/personal/evaluation/evaluation-detail' });
      },
      /** @description : 녹취콜 리스트 모달 */
      async onRecordList(data) {
        const payload = {
          actionname: 'EWM_PERSONAL_EVALUATION_ROUND_RECORD',
          data: {
            scheId: data.id,
            roundId: data.evalRound ? data.evalRound : '',
          },
          loading: false,
          useErrorPopup: true,
        };
        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.onOpenModal(
            'ModalRecordList',
            {
              title: '녹취콜 리스트',
              buttons: {
                cancel: { text: '닫기' },
              },
              width: 1000,
              height: 500,
            },
            this.$_commonlib.cloneObj(res.data.data),
          );
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return this.$_commonlib.formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-id') {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            /* if (!params.sort) {
						params.sort = sort;
					} */
            vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.dayEnd;

            params = { ...params, ...vm.searchType.paramsData };

            const payload = {
              actionname: 'EWM_PERSONAL_EVALUATION',
              data: params,
              loading: false,
              useErrorPopup: true,
            };

            const res = await vm.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              let data = res.data.data; //.filter(d => d.evalProcessCd == 1179 || d.evalProcessCd == 1180 || d.evalProcessCd == 1181);
              data.forEach((d, index) => {
                d.evalOrd = index + 1;
              });
              const rtnData = {
                data: data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.personalEvaluation.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      onDayStartChanged(e) {
        const changed = e.value.slice(0, 6) + '01';
        this.searchType.customTypes.dayStart = changed;
      },
      onDayEndChanged(e) {
        const changed = e.value.slice(0, 6) + '29';
        this.searchType.customTypes.dayEnd = changed;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.evalDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
          //this.codes.evalProcessCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_process'], 2);

          this.$refs.personalEvaluation.getGridInstance.columnOption('evalDivisionCd', 'lookup', this.codes.evalDivisionCd);
          //this.$refs.personalEvaluation.getGridInstance.columnOption('evalProcessCd', 'lookup', this.codes.evalProcessCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.onChangeSearchDay();
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
</style>
